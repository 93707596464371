import { forwardRef } from 'react'
import { Button as ReactAriaButton, type ButtonProps as ReactAriaButtonProps } from 'react-aria-components'
import { cn } from '#app/utils/tailwind-merge.ts'

export interface ButtonProps extends ReactAriaButtonProps {
	variant?: 'primary' | 'secondary' | 'ghost' | 'danger'
	size?: 'sm' | 'md' | 'lg'
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ variant = 'primary', size, className, ...props }, ref) => {
	return <ReactAriaButton className={cn('button', variant, size, className)} ref={ref} {...props} />
})
Button.displayName = 'Button'

export { Button }
